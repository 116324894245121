<template>
  <div>
    <div v-if="items.length > 0">
      <v-radio-group v-model="selectedItem" mandatory>

        <div v-for="(section, index) in sections" v-bind:key="index" class="tw-border tw-border-gray-n1 tw-rounded-lg tw-p-6 tw-mb-4">
          <h3 class="tw-text-black tw-text-xl tw-font-semibold tw-leading-relaxed tw-mb-4">{{ section[0].paymentMethod.name }}</h3>
          <table class="tw-w-full align-top-table">
            <thead>
            <tr class="tw-text-black tw-text-sm tw-font-semibold tw-leading-relaxed">
              <th class="tw-pb-4 tw-w-20" scope="col">Selected</th>
              <th class="tw-pb-4" scope="col">Initial Deposit</th>
              <th class="tw-pb-4" scope="col">Second Payment</th>
              <th class="tw-pb-4" scope="col">Final Payment</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item,index) in section" :key="index">
              <th scope="row">
                <v-radio color="#2d2d2d" :value="item" @click="update(item)" :ripple="false"/>
              </th>
              <td>{{ item.paymentMethod.deposit }}%</td>
              <td>{{ item.paymentMethod.second_payment }}%</td>
              <td>
                <div class="tw-flex">
                  {{ item.paymentMethod.final_payment }}%
                  <div class="tw-flex tw-flex-col tw-ml-3">
                      <v-radio-group :disabled="!item.active" v-model="item.selectedPaymentDueNotification" class="condense-radio">
                        <v-radio
                            v-for="(notification,index) in item.paymentMethod.paymentDueNotifications" :key="index"
                            color="#2d2d2d"
                            :ripple="false"
                            :value="notification"
                            :label="notification.name"
                            @click="update(item)"
                        />
                      </v-radio-group>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </v-radio-group>

    </div>

  </div>
</template>

<script>

//import { debounce } from 'vue-debounce'
import i18n from '@/i18n'
export default {

  middleware: ['auth', 'vendor', 'is-vendor-active'],
  metaInfo() {
    return {
      title: i18n.t('payment-methods'),
        meta:[
          {
            name: 'description',
            content: i18n.t('description.vendor/payment-methods'),
          },
        ]
    }
  },
  data() {
    return {
      paymentMethod: null,
      valid: true,
      lazy: true,
      loading: false,
      items: [],
      errored: false,
      selectedItem: null
    }
  },
  watch: {
    "items": function () {
      // if(this.selectedItem) this.activatePaymentMethod(this.paymentMethod)
      this.selectedItem = this.items.filter(item => item.active === true)[0];
    },
  },
  mounted() {
    this.getItems();

  },
  computed: {
    sections() {
      return this.items.reduce((r, v) => ((r[v.paymentMethod.section] || (r[v.paymentMethod.section] = [])).push(v), r), {})
    },
    activeMethod() {
      return this.items.filter(item => item.active === true);
    }
  },
  methods: {
    async update(item) {
      //this.selectedItem = item;
      if(!item.selectedPaymentDueNotification){
        if(item.paymentMethod.paymentDueNotifications.length){
          let arr1 = {
            id : item.paymentMethod.paymentDueNotifications[0].id
          }
          this.selectedItem.selectedPaymentDueNotification = arr1;
        }

      }
      await this.activatePaymentMethod(this.selectedItem);
      //await this.getItems();
    },
    activatePaymentMethod(item) {
      this.loading = true;

      item.active = 1; // todo: BE requires 1 to be sent, fix it on BE side

      let uri = '/api/vendor/payment-methods/' + item.id;

      this.$http.patch(uri, item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success")
            this.items = response.data.data
          })
          .catch(error => {
            this.$toast.warning(error.data.message, "Warning")
          });
    },
    getItems() {
      this.loading = true;
      let uri = '/api/vendor/payment-methods';
      this.$http.get(uri)
          .then(({data}) => {
            this.items = data.data
          })
          .catch(error => {
            console.log('Error loading data: ' + error)
          }).finally(() =>
          this.loading = false
      )
    },
  },
}
</script>
